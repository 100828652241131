.give_access_btn{
    margin-top: 32px;
    width: 160px;
    height: 48px;
    border: 1px solid #1E376F;
    border-radius: 12px;
    color: #1E376F;
}
@media (max-width: 900px) {
    .give_access_btn {
      margin: 0px auto 20px;
    }
}