.search_filter_cont{
    margin-top: 24px;
}

.apply_button_block{
    width: 88px;
    margin-left: 24px;
}
.clear_button_block{
margin-left: 30px;
}
.clear_button{
    width: 109px;
    height: 48px;
    border: 1px solid #1E376F;
    border-radius: 12px;
    color: #1E376F;
    background: white;
}
.clear_button img{
    margin-right: 5px;
}
.search_filter_cont_search, .search_filter_cont_calendar{
    max-width: 241px;
    width: 100%;
    position: relative;
    margin-left: 30px;
}
.search_filter_cont_search img{
  position: absolute;
    left: 7%;
}

.search_filter_cont_search input{
    padding: 12px 12px 12px 48px;
}
.search_filter_cont_calendar input{
    padding: 12px 48px 12px 16px;
}
.search_filter_cont_calendar input::placeholder{
   color: #212121;
}
.search_filter_cont_select{
    max-width: 241px;
    width: 100%;
    height: 48px;
    margin-left: 30px;
    white-space: nowrap;
}
.search_filter_cont_select:first-child{
    margin-left: 0px;
}


