.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: url("../../assets/images/right_arrow.png");

}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: url("../../assets/images/left_arrow.png");
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -50px !important;
    left: auto;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -50px !important;
    right: auto;
}
.swiper {
    position: unset;

}
 @media screen and (min-width: 576px) {
  .swiper-container {
    width: 576px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}