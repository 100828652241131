.subScribe_btn_block {
    margin-top: 24px;
    width: 122px;
}

.subScribe_block_cont {
    margin: 120px auto 0px;
    max-width: 1325px;
    width: 100%;
}

.subScribe_block_cont_imgBlock {
    max-width: 649px;
    width: 100%;
    margin-right: 36px;
}

@media (max-width: 900px) {
    .subScribe_block_cont {
        margin: 60px auto 0px;
        text-align: center;
    }
    .subScribe_block_cont_imgBlock {
        margin-right: 0px;
    }
    .subScribe_btn_block {
        margin: 24px auto 0;
    }

}