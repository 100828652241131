@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
}

* {
    margin: 0;
    padding: 0;
    font-family: "Roboto";
    box-sizing: border-box;
    color: #212121;
    border: none;
    outline: none;
}

button {
    cursor: pointer;
    border: none;
    background: transparent;
}

.container {
    max-width: 1620px;
    width: 100%;
    margin: 0 auto;
}
.container_inner {
   padding: 0px 10px;
}


.container_small {
    max-width: 1378px;
    width: 100%;
    margin: 0 auto;
}

.d-grid {
    display: grid;
    align-items: center;
    justify-items: center;
}

.grid-columns-4fr {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-gab {
    grid-gap: 30px;
}

.d-flex {
    display: flex;
}

.fd-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-start{
    justify-content: start;
}


.justify-content-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.f-400 {
    font-weight: 400;
}

.f-500 {
    font-weight: 500;
}

.f-600 {
    font-weight: 600;
}

.f-700 {
    font-weight: 700;
}

.fs-14 {
    font-size: 14px;
    line-height: 21px;
}

.fs-16 {
    font-size: 16px;
    line-height: 24px;
}

.fs-18 {
    font-size: 18px;
    line-height: 27px;
}

.fs-24 {
    font-size: 24px;
    line-height: 36px;
}

.fs-32 {
    font-size: 32px;
    line-height: 42px;
}

.fs-48 {
    font-size: 48px;
    line-height: 62px;
}

.bc-white {
    background-color: #FFFFFF;
}

.bc-blue {
    background-color: #1E376F;
}

.bc-grey {
    background-color: #D9D9D9;
}

.bc-blueGrey {
    background-color: #E6EAF1;
}

.c-white {
    color: #FFFFFF;
}

.c-greenBlue {
    color: #2EBAD5;
}

.c-red {
    color: red;
}

input:focus {
    outline: none;
}

.text-center {
    text-align: center;
}

.error {
    color: red;
    font-size: 16px;
    margin-top: -15px;
    margin-bottom: 10px;
    text-align: center;
}
.main{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media(max-width: 1160px){
    .grid-columns-4fr {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1024px) {

    .fs-48 {
        font-size: 42px;
        line-height: 56px;
    }
}
@media(max-width: 900px){
    .flex-wrap {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media (max-width: 768px) {
    .text-center_mobile {
        text-align: center;
    }

    .fs-48 {
        font-size: 24px;
        line-height: 36px;
    }

    .grid-columns-4fr {
        grid-template-columns: 1fr;
    }
    .fs-32 {
        font-size: 24px;
        line-height: 32px;
    }
}
