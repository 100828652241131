.questions_cont{
    margin: 120px 0px;
    padding: 0px 15px;
}
.accordion{
    max-width: 781px;
    width: 100%;
    cursor: pointer;
    margin: 0px 10px;
}
.accordion_block{
    padding: 24px 0px;
    border-bottom: 1px solid #C1C9DD;
}
.accordion_title{

}
.accordion_desc_block{
    transition-delay: 10s;
}
.accordion_description{
    margin-top: 12px;
}
.accordion_desc_block_active{
    display: block;
    background-color: #2EBAD5;
}
@media (max-width: 768px){
    .questions_cont {
        margin: 60px 0px;
    }
}