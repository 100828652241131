.account_info_cont {
    max-width: 785px;
    width: 100%;
    margin: 0px 10px;
}

.account_info_title span {
    margin-left: 10px;
}

.account_info_block_cont {
    margin: 24px auto 0;
}

.account_info_block {
    margin-bottom: 30px;

}

.account_info_block_item {
    margin-right: 30px;

}

.account_info_block_item:last-child {
    margin-right: 0px;
}

.account_info_block_item input {
    margin-top: 4px;
    width: 378px;
    padding: 12px 16px;
    border: 1px solid #E6EAF1;
}

.save_changes_btn {
    width: 147px;
}

@media (max-width: 768px) {
    .account_info_block_item {
        margin-right: 0px;
    }

    .account_info_cont {
        max-width: 385px;
        margin: 30px auto 0px;
        padding: 0px 10px;
    }
    .account_info_title{
        text-align: center;
    }
    .account_cont_inner {
        margin: auto;
        justify-content: center;
    }
    .account_info_block_item input {
        width: 278px;
    }
    .save_changes_btn {
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .account_info_block_item {
        margin-top: 15px;

    }

}