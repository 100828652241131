.close_icon {
    margin-left: 24px;
    cursor: pointer;
}

.modal_container {
    width: 785px;
    border-radius: 12px;
    max-height: 880px;
    height: 100%;
}

.modal_container_inner {
    padding: 12px;
}

.modal_profile_pic {
    width: 48px;
    height: 48px;
    margin-right: 16px;
}

.modal_blocks {
    margin-top: 26px;
}
.modal_blocks_2 {
    margin-left: 24px;
}

.modal_video {
    width: 331px;
    height: 309px;
    position: relative;
}

.modal_video_name_block {
    margin-top: 14px;
}

.modal_likes_views_block {
    margin-top: 24px;
    padding: 24px 0px;
    border-bottom: 1px solid #E6EAF1;
    border-top: 1px solid #E6EAF1;
    margin-bottom: 8px;
}

.modal_likes_block {
    padding: 0px 40px;
    border-right: 1px solid #E6EAF1;
}

.modal_views_block {
    padding: 0px 60px;
}

.modal_accordion {
    cursor: pointer;
    margin: 0px 24px;
}

.ad_country_block {
    margin-top: 24px;
}

.country_flag_block {
    width: 72px;
    height: 72px;
    margin-right: 30px;
}

.share_ad_btn_block {
    width: 183px;
    margin-top: 4px;
}

.links_block {
    margin-top: 24px;
    padding: 8px 0px 24px;
    border-top: 1px solid #E6EAF1;
}

.links_block1{
    border-right: 1px solid #cccccc;
    width: 183px;
}
.links_block2{
    width: 183px;
    padding-left: 16px;
}

.links_block1_1,  .links_block2_1{
    border-bottom: 1px solid #cccccc;
    padding: 16px 0px;
    width: 167px;
}
.links_block1_1 div,.links_block1_2 div,  .links_block2_1 div, .links_block2_2 div{
    margin-top:4px;
}
.links_block1_1 div img, .links_block1_2 div img,  .links_block2_1 div img, .links_block2_2 div img{
    margin-right:8px;
}

.links_block1_2, .links_block2_2{
    padding: 16px 0px;
    width: 167px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.45) !important;
    border-radius: 12px;
}
.ReactModal__Content .ReactModal__Content--after-open{
    width: 785px !important;
}
.ReactModal__Content--after-open {
    border-radius: 12px !important;
    margin-bottom: 24px !important;
}