.contact_us_container{
    margin:120px auto 142px;
    /*height: 84.5vh;*/
}
.contact_us_cont{
    max-width: 514px;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin: auto;
}
.contact_us_cont_inner{
    padding:32px 40px;
}
.contact_us_btn{
    cursor: pointer;
    text-decoration: none;
}
.input_block textarea{
   border: 1px solid #E6EAF1;
    padding: 5px;
}
.input_block textarea:focus{
  outline: none;
}
@media (max-width: 900px) {

    .contact_us_container{
        margin:60px auto 72px;
    }
}