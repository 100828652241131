.HomeBlock4_cont {
    margin: 120px auto 0px;
    max-width: 1325px;
    width: 100%;
}

.HomeBlock4_cont_imgBlock {
    max-width: 640px;
    width: 100%;
    height: 388px;
    margin-right: 36px;
}

.HomeBlock4_cont_title {
    margin-bottom: 16px;
}

.HomeBlock4_cont_content {
    margin-bottom: 30px;
}

.HomeBlock4_cont .CTA_button {
    margin-top: 32px;
    width: 160px;
    height: 48px;
    border: 1px solid #1E376F;
    border-radius: 12px;
    color: #1E376F;
}

@media (max-width: 900px) {
    .HomeBlock4_cont_imgBlock {
        margin-right: 6px;
    }

    .HomeBlock4_cont {
        margin: 60px auto 0px;
    }

    .HomeBlock4_cont div {
        margin: 20px auto 0px;
        padding: 0px 10px;
        text-align: center;
    }

    .HomeBlock4_cont .CTA_button {
     margin: 20px auto 20px;
    }
}