.HomeBlock2_cont{
    margin: 120px auto 0px;
    max-width: 1325px;
    width: 100%;
}
.HomeBlock2_cont_imgBlock{
    max-width: 640px;
    /*width: 100%;*/
    /*height: 388px;*/
    margin-left: 31px;
}
.HomeBlock2_cont_title{
    margin-bottom: 16px;
}
.HomeBlock2_cont_content{
    margin-bottom: 12px;
}
.HomeBlock2_cont .CTA_button{
    margin-top: 32px;
    width: 122px;
    height: 48px;
    border: 1px solid #1E376F;
    border-radius: 12px;
    color: #1E376F;
}

.priceList_ul{
    margin: 10px;
    padding-left: 8px;
}
.priseList_ul li::before {
    /*content: url(../../../../assets/images/Ellipse_small_blue.png);*/
    font-weight: bold;
    color: blue;
    display: inline-block;
    width: 2em;
    margin-left: -1.2em;
}
.priseList_ul li{
    margin-bottom: 8px;
}

@media (max-width: 1370px ) {

}
@media (max-width: 900px) {
    .HomeBlock2_cont div{
        margin:  auto ;
        text-align: center;
    }
    .HomeBlock2_cont ul{
        margin: auto;
    }
    .HomeBlock2_cont .CTA_button{
        margin: 10px auto 20px;
    }
    .HomeBlock2_cont{
        margin-top: 60px;
        padding: 0px 10px;
    }
}