.account_cont{
    max-width: 1088px;
   width: 100%;
    margin: 48px auto;
}
.account_cont_inner{
    padding: 0px 15px;
    margin: auto;
}
@media (max-width: 768px) {
    .account_cont{
        margin: 8px auto;
    }
}