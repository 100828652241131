.reviews_cont{
    margin-top: 120px;
}
.reviews_cont_items_block{
    margin-top: 24px;
    position: relative;
}
.reviews_cont_item{
    margin-right: 30px;
    max-width: 513px;
    width: 100%;
    border: 1px solid #E6EAF1;
    border-radius: 13px;
}
.reviews_cont_item:last-child{
    margin-right: 0px;
}
.reviews_cont_item_inner{
    padding: 24px 32px;
}
.reviews_cont_item_title{

}
.reviews_stars{
    margin: 12px 0px 7px;
   color: #DAC400;
}
@media (max-width: 900px) {
    .reviews_cont{
        margin-top: 60px;
    }
}