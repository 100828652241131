.header_login_container {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}

.header_login_container_mobile {
    display: none;
}

.header_logout_container {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}

.header_logout_mobile_container {
    display: none;
}

.header_container_inner {
    padding: 16px 0px;
}

.header_login_content {
    max-width: 280px;
    width: 100%;
    margin-left: 10px;
}

.header_logout_content {
    max-width: 380px;
    width: 100%;
    margin-left: 10px;
}

.pricing_btn:hover, .faq_btn:hover, .contact_us_btn:hover, .feed_btn:hover, .saved_ads:hover, .my_account_btn:hover {
    color: #2EBAD5;
    -ms-transform: scale(0.2); /* IE 9 */
    -webkit-transform: scale(0.2); /* Safari 3-8 */
    transform: scale(1.1);
}

.logo {
    cursor: pointer;
}

.header_login_container .pricing_btn, .faq_btn, .contact_us_btn {
    cursor: pointer;
    text-decoration: none;
}

.header_logout_content .feed_btn, .saved_ads, .my_account_btn {
    text-decoration: none;
    cursor: pointer;
}

.header_login_content div {
    margin-left: 10px;
}

.header_login_content div:first-child {
    margin-left: 0px;
}

.log_reg_block {
    margin-left: 10px;
}

.login_btn {
    cursor: pointer;
    white-space: nowrap;
}

.logout_btn {
    cursor: pointer;
}

.Register_btn {
    width: 107px;
    margin-left: 48px;
}

@media (max-width: 768px) {
    .header_login_container {
        display: none;
    }

    .header_login_container_mobile {
        display: block;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }
    .header_logout_container{
        display: none;
    }
    .header_logout_mobile_container{
        display: block;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    }

    .menu_burger {
        width: 25px;
        height: 25px;
        margin-right: 20px;
    }

    .header_mobile_container_inner {
        padding: 16px 10px;
        width: 100%;
    }
    .pricing_btn_mobile, .faq_btn_mobile, .contact_us_btn_mobile, .login_btn_mobile{
        padding: 10px 5px;
        text-decoration: none;
    }
   .pricing_btn_mobile:hover, .faq_btn_mobile:hover, .contact_us_btn_mobile:hover, .login_btn_mobile:hover{
       background-color: #61dafb;
   }
   .feed_btn, .saved_ads, .my_account_btn, .contact_us_btn, .logout_btn{
       padding: 10px 5px;
       text-decoration: none;
   }

    .header_dropDown {
        position: relative;
    }

    .header_dropDown_content {
        background: white;
        position: absolute;
        top: 50px;
        right: 45%;
        width: 180px;
        padding: 10px 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        z-index: 999;
    }

    .Register_btn {
        width: 87px;
        margin-left: 20px;
    }
}

@media (max-width: 360px) {
    .menu_burger {
        width: 25px;
        height: 25px;
        margin: 0px 10px;

    }

    .Register_btn {
        width: 87px;
        margin-left: 10px;
    }
}