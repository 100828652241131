.select_cont{
position: relative;
}

.vSelect{
    border: 1px solid #E6EAF1;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    color: #212121;
    padding: 12px 30px 12px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Positions background arrow image */
    /*background-image: url("../../assets/images/arrow_select_down.png");*/
    background-repeat: no-repeat;
    background-position: 94% center;
    white-space: nowrap;

}
.select_item_cont {
    width: 239px;
    border: 1px solid #E6EAF1;
    z-index: 999;
    position: relative;
    background: white;
}
.select_item_cont_likes{
    width: 515px;
    border: 1px solid #E6EAF1;
    z-index: 999;
    position: relative;
    background: white;
}

.select_item{
    width: 100%;
    padding: 12px 15px 12px 27px;
    cursor: pointer;
}
.select_item:hover{
    background: rgb(204, 234, 239);
}
.select_dropdown_arrow{
    position: absolute;
    top: 43%;
    right: 5%;
    cursor: pointer;
}
.select_item input{
    width: 20px;
    height: 20px;
}
.select_item_checkmark{
    margin-left: 12px;
}

/* When the checkbox is checked, add a blue background */
.select_item input:checked .select_item_checkbox{
    background-color: #2EBAD5;
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.select_item_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.select_item input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.select_item .checkmark:after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*.select_item_checkbox{*/
/*    accent-color: #2EBAD5;*/
/*    border: none !important;*/
/*}*/
.range_block{
    margin-top: 20px;
}
/*.range_block input{*/
/*    !*margin: .4rem;*!*/
/*    width:460px;*/
/*}*/
.range_block .reset_btn{
text-decoration: underline;
    margin-top: 16px;
    color: #9E9E9E;
}

.select_item_checkbox[type=radio] {
    border: 1px solid #1E376F;
    padding: 0.5em;
    -webkit-appearance: none;
    cursor: pointer;
}

.select_item_checkbox[type=radio]:checked {
    background: #1E376F no-repeat center center;
    background-size: 9px 9px;
}

.select_item_checkbox[type=radio]:focus {
    outline-color: #1E376F;
}
.range-slider .range-slider__thumb {
    position: absolute;
    z-index: 3;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #1E376F !important;
}
.range-slider .range-slider__range {
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    height: 100%;
    background: rgba(30, 55, 111, 0.81) !important;
}

