.password_changes_block{
    margin-top: 6px;
    width: 221px;
    margin-left: 12px;
}
.account_pass_cont{
    max-width: 785px;
    width: 100%;
}
.account_pass_title{
    margin-bottom: 24px;
}
.account_pass_input_block{
    margin-bottom: 24px;
    max-width: 378px;
    width: 100%;
    padding: 12px 16px;
    position: relative;

}
.account_pass_input_block input{
    margin-top: 4px;
    padding: 12px 16px;
    border: 1px solid #E6EAF1;
    border-radius: 4px;
}
.account_pass_input_block img{
    position: absolute;
    right: 7%;
    bottom: 28%;
}

.account_pass_input_block input::placeholder{
    font-size: 16px;
    line-height: 24px;
    color: #BDBDBD;
}
@media (max-width: 768px){
    .account_pass_cont{
       margin: 30px auto;

    }
    .account_pass_title{
      text-align: center;
    }
    .account_pass_input_block {
     margin: auto;
    }
    .password_changes_block {
      margin: auto;
    }
}