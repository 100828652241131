.priceList_cont{
    margin-top: 120px;
}
.priceList_items_block{
    margin-top: 24px;
    padding: 0px 15px;
    width: 100%;
}
.priceList_item{
    max-width: 376px;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-left: 10px;
    cursor: pointer;
}
.priceList_item:first-child{
    margin-left: 0px;
}
.priceList_item_inner{
    padding: 32px 22px;
}
.priceList_getStarted_btn{
    margin-top: 14px;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
}
.HomeBlock2_ul{
    margin: 0px;
    padding-left: 15px;
}
.HomeBlock2_ul li::before {
    content: url(../../../../assets/images/Ellipse_small_blue.png);
    font-weight: bold;
    color: blue;
    display: inline-block;
    width: 2em;
    margin-left: -1em;
}
.HomeBlock2_ul li{
    margin-bottom: 16px;
}
@media (max-width: 768px){
    .priceList_item{
       margin-bottom: 20px;
    }
    .priceList_cont {
        margin-top: 60px;
    }
}