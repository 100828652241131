.searchItems_container{
    margin-top: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;

}
.searchItems_block {
    max-width: 242px;
    width: 100%;
    background: #FFFFFF;
    /* Main/Dark Blue/Tint 9 */

    border: 1px solid #E6EAF1;
    border-radius: 12px;
}

.searchItems_block_inner {
    padding: 16px;
}

.profile_pic {
    width: 24px;
    height: 24px;
    margin-right: 9px;
}
.profile_name{

}

.fav_icon {
    width: 12px;
    height: 16px;
    cursor: pointer;
}

.calendar_block {
    margin-top: 13px;
}

.calendar_block img {
    margin-right: 11px;
}

.calendar_block div {
    color: #757575;
}

.video_block {
    margin-top: 12px;
    border-radius: 12px;
    max-width: 210px;
    width: 100%;
    position: relative;
}

.play_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 500ms ease;
    display: block;
}
.play_btn:hover {
    opacity: 1;
    display: block;
}

.video_name_block {
    margin-top: 8px;
    margin-bottom: 8px;
}
.video_name_block_title{
    display: -webkit-box;
    width: 150px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video_name_btn {
    padding: 4px 12px;
    border: 1px solid #BDBDBD;
    border-radius: 12px;
    width: 50px border-box;
    height: 29px;
}
.likes_views_block{
    padding:8px 0px;
    border-bottom: 1px solid #E6EAF1;
    border-top: 1px solid #E6EAF1;
    margin-bottom: 8px;
}
.likes_block{
    padding:0px 20px;
    border-right: 1px solid #E6EAF1;
}
.views_block{
    padding:0px 20px;
}
