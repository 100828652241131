.footer_cont {
    width: 100%;
    height: 59px;
}

.footer_cont_mobile {
    display: none;
}

.footer_cont_inner {
    padding: 16px 0px;
}

.footer_item {
    padding: 0px 16px;
    border-right: 1px solid white;
    cursor: pointer;
    text-decoration: none;
}

.footer_items_block a:hover {
    color: #61dafb;
}

.footer_items_block div:first-child {
    padding-left: 0px;
}

.footer_items_block div:last-child {
    border-right: none;
}

@media (max-width: 945px) {
    .footer_cont {
        display: none;
    }

    .footer_cont_mobile {
        display: block;
    }

    .footer_items_block div {
        padding: 7px 10px 7px;
        border-right: none;
        text-decoration: underline;
    }

    .footer_items_block div:first-child {
        padding-left: 10px;
    }

    .all_rights {
        padding-bottom: 10px;
        margin-right: 0px;
        margin-top: 15px;
    }

    .footer_items_block div {
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .footer_item {
        border-right: none;
    }
}

@media (max-width: 360px ) {
    .footer_items_block div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: -15px;
    }
    .footer_item {
       text-align: center;
        margin-bottom: 10px;
    }
    .all_rights {
       text-align: center;
    }
}
