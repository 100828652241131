.homeCarousel_cont{
    margin-top: 120px;
    padding: 0px  15px;
}
.omeCarousel_cont_items_block{
    margin: 24px auto 0px;
}

.homeCarousel_cont_item_img{
    width: 66px;
    height: 66px;
}
.homeCarousel_cont_item{
    max-width: 379px;
    height: 237px;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    cursor: pointer;
    margin-left: 10px;
}
.homeCarousel_cont_item_inner{
    padding: 24px 32px;
}
.homeCarousel_cont_item_title{
    margin-top: 16px;
}
@media (max-width: 768px){
    .homeCarousel_cont{
        margin-top: 60px;
    }
}
