.player{
    padding-top: 0px !important;
    height: 196px;
}
.video-react-video{
    /*max-width: 210px !important;*/
    width: 100%;
}
.videoBlock_cont{
    /*max-width: 210px;*/
    width: 100%;
    height: max-content;
}
.video_block_video{
    /*max-width: 210px;*/
    width: 100%;
}