.account_info_btn, .account_info_btn_active{
    border-top:1px solid #B4EAF4 ;
}

.account_info_btn, .billing_btn, .invoice_btn, .ip_btn, .password_btn {
    border-bottom: 1px solid #B4EAF4;
    padding: 12px 9px 12px 36px;
    cursor: pointer;
}
.account_info_btn_active, .billing_btn_active, .invoice_btn_active, .ip_btn_active, .password_btn_active{
    border-bottom: 1px solid #B4EAF4;
    border-left: 2px solid #2EBAD5;
    padding: 12px 9px 12px 36px;
}
.account_info_btn:hover{
    background: #2EBAD5;
}

.account_info_btn_active:before{
    content: url('../../assets/images/account_info_active.png');
    display: inline-block;
    margin-right: 8px;
}
.account_info_btn:before{
    content: url('../../assets/images/account_not_active.png');
    display: inline-block;
    margin-right: 8px;
}
.billing_btn_active:before{
    content: url('../../assets/images/biling_active.png');
    display: inline-block;
    margin-right: 8px;
}
.billing_btn:before{
    content: url('../../assets/images/biling_notactive.png');
    display: inline-block;
    margin-right: 8px;
}
.invoice_btn:before{
    content: url('../../assets/images/invoice_notactive.png');
    display: inline-block;
    margin-right: 8px;
}
.invoice_btn_active:before{
    content: url('../../assets/images/invoice_active.png');
    display: inline-block;
    margin-right: 8px;
}
.ip_btn:before{
    content: url('../../assets/images/ip_notactive.png');
    display: inline-block;
    margin-right: 8px;
}
.ip_btn_active:before{
    content: url('../../assets/images/ip_active.png');
    display: inline-block;
    margin-right: 8px;
}
.password_btn:before{
    content: url('../../assets/images/password_notactive.png');
    display: inline-block;
    margin-right: 8px;
}
.password_btn_active:before{
    content: url('../../assets/images/password_active.png');
    display: inline-block;
    margin-right: 8px;
}

.navbar_cont {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 242px;
    /*position: fixed;*/
    /*overflow: auto;*/
    height: max-content;
}
.navbar_cont_mobile{
    display: none;
}

.navbar_cont li {
    display: block;
    padding: 12px 9px 12px 36px;
    text-decoration: none;
}
.navbar_cont li:hover {
   background: #c3e8f3;
}


li :hover:not(.active) {
    background-color: #555;
    color: white;
}

.password_btn.account_pass_cont{
    display: block;
}

@media (max-width: 768px){
    .navbar_cont{
        display: none;
    }
    .navbar_cont_mobile{
        list-style-type: none;
        display: block;
       margin-left: 30px;
    }
    .navbar_cont_content_mobile{
        margin-right: 20px;
        margin-top: 20px;
        /*position: absolute;*/
        /*width: 100%;*/
        /*left: 50%;*/
    }
    .navbar_cont_content_mobile li{
        white-space: nowrap;
    }

    .menu_burger_navbar{
        width: 25px;
        height: 25px;

    }
    .account_cont_inner {
       display: unset;
        padding: 0px 20px;
    }
}