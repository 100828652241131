.refund_policy_cont{
    max-width: 1086px;
    padding: 0px 15px;
    width: 100%;
    margin: 40px auto;
}
.refund_policy_title{
    margin-bottom: 16px;
}
.refund_policy_title2{
    margin: 31px 0px 16px;
}
.refund_policy_ul{
    margin-top: 16px;
    padding-left: 20px;
}
.refund_policy_ul li::before {
    content: url(../../assets/images/Ellipse_small_blue.png);
    font-weight: bold;
    color: blue;
    display: inline-block;
    width: 1.5em;
    margin-left: -1.1em;
}
.refund_policy_ul li{
    margin-bottom: 8px;
    padding: 8px 0px;
}
