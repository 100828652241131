.calendar_cont{
    max-width: 241px;
    width: 100%;
    position: relative;
    margin-left: 30px;
}
.calendar_dropDawn{
    position: absolute;
    top: 110%;
    right: 0%;
    z-index: 999;
}
.calendar_icon{
    margin-top: 10px;
    cursor: pointer;
    position: absolute;
    right: 2%;
    z-index: 22;
}

.react-calendar__tile--active {
    background: #2ebad5 !important;


}
.react-calendar__tile--active  .react-calendar *:after {
    box-sizing: border-box;
    /*color: white !important;*/
}
.react-calendar__tile{
    color: #FFFFFF !important;
}
.react-calendar__tile--now {
    background: #c3e8f3 !important;
}
.react-datepicker-wrapper {
     width: 45% !important;
}
.DatePicker_cont{
    border: 1px solid #E6EAF1;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    color: #212121;
    padding: 12px 30px 12px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Positions background arrow image */
    /*background-image: url("../../assets/images/arrow_select_down.png");*/
    background-repeat: no-repeat;
    background-position: 94% center;
    white-space: nowrap;
}
.DatePicker_cont:after{
   content: url("../../assets/images/calendar_icon.png");
   position: absolute;
    right: 2%;
    bottom: 10%;
}
.DatePicker_cont div{
    /*width: 20%;*/
}
.react-datepicker__input-container input {
    width: 100%;
    font-size: 16px;
}
