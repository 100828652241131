.GetStarted_add_back {
    background-image: url("../../../../assets/images/Mask group.png");
}

.getStarted_cont {
    padding: 32px 10px 0px;
}

.getStarted_title {
    max-width: 600px;
    width: 100%;
}

.getStarted_btn {
    margin-top: 24px;
    width: 120px;
    height: 48px;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;
}

.getStarted_img_block {
    max-width: 513px;
    width: 100%;
}

@media (max-width: 768px) {
    .getStarted_cont .align-items-start {
        align-items: center;
    }

    .getStarted_img_block {
        margin-top: 20px;
    }
}
