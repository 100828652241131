.vbutton {
    height: 48px;
    border-radius: 12px;
    border: transparent;
    background: #1E376F;
    color: white;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
}
.vbutton:hover{
    background: #2c4a9e;
}
