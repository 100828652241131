.login_container{
    /*position: relative;*/
    /*height: 84.5vh;*/
}
.login_cont{
    max-width: 514px;
    width: 100%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin: 120px auto 142px;
}
.login_cont_inner{
    padding:32px 40px;
}
.inputs_blocks_cont{
    margin: 24px 0px;
}
.input_block{
    margin-bottom: 24px;
}
.input_block input{
    margin-top: 4px;
    padding: 12px 16px;
    border: 1px solid #E6EAF1;
    border-radius: 4px;
}
.input_block input::placeholder{
   font-size: 16px;
    line-height: 24px;
    color: #BDBDBD;
}
.forgot_pass{
    margin-top: 16px;
    text-decoration: underline;
    cursor: pointer;
}
.login_cont_loginBtn{
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 12px;
    cursor: pointer;
}
.dont_account div{
    margin-left: 5px;
    cursor: pointer;
}
@media (max-width: 900px){
    .login_cont{
        margin: 60px auto 72px;
        padding: 0px 10px;
    }
    .login_cont_inner {
        padding: 22px 25px;
    }
}