.register_cont {
    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    max-width: 784px;
    width: 100%;
    margin: 52px auto 120px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}
.register_cont_inner{
    padding: 32px 40px;
}
.register_cont .input_block{
    width: 100%;
    margin-left: 24px;
}
.register_cont .input_block:first-child{
    margin-left: 0px;
}
.register_cont_registerBtn{
    width: 107px;
    padding: 12px 24px;
    border-radius: 12px;
    margin-bottom: 24px;
    cursor: pointer;
}
.terms_block{
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.terms_block input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border: 1px solid #1E376F;
    border-radius: 5px;
}
.terms_block:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.terms_block input:checked ~ .checkmark {
    background-color: #2EBAD5;
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.terms_block input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.terms_block .checkmark:after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.terms_block_general a{
    margin: 0px 5px;
    text-decoration: underline;
}
.input_block select{
    margin-top: 4px;
    padding: 12px 16px;
    border: 1px solid #E6EAF1;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Positions background arrow image */
    background-image: url("../../assets/images/arrow_select_down.png");
    background-repeat: no-repeat;
    background-position: 96% center;
}
.input_block select:focus{
   outline: none;
}
@media(max-width: 900px){
    .register_cont .input_block {
        margin-left: 0px;
    }
    .register_cont {
      padding: 0px 10px;
    }
}
@media(max-width: 768px){
    .register_cont_inner {
        padding: 12px 15px;
    }
    .register_cont {
        margin: 30px auto 30px;
    }
}



